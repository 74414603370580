import { Link } from "@reach/router"
import React, { useEffect, useState } from "react"
import Contact from "../components/Contact"
import JapaneseForm from "../components/forms/JapaneseForm"
import MexicoForm from "../components/forms/MexicoForm"
import OtherForm from "../components/forms/OtherForm"
import { Seo } from "../components/seo"
import { Input, SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"

const JobForm = (props) => {
  const [workid, setWorkId] = useState("0");
  const [sForm, setSForm] = useState({
    selectForm: "1",
  })
  
  useEffect(() =>{
    getWorkId();
  })

  const getWorkId = async () => {
    const aux = await props.location.state.workid;
    setWorkId(aux);
  }

  const handleChange = e => {
    const { name, value } = e.target
    setSForm(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <>
      <Seo
        title={`メキシコ在住者お申し込みフォーム | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container contact-us">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="contact-us__responsible-for-company">
              <SectionTitle> 無料相談フォーム</SectionTitle>
              <hr className="u-line-bottom" />

              <p>
                弊社サポートの詳細は『
                <Link to="/process">お仕事紹介の流れ</Link>
                』をご覧ください。
              </p>
              <p>
                弊社では出来る限りご登録者様のご希望に沿えるよう、必要に応じて募集企業へ雇用条件の相談なども行っております。※ご希望によってはご相談を承り兼ねる場合がございます。
              </p>
              <form action="" className="contact-us__form-contact mb-30">
                <Input className="form-responsive">
                  <label htmlFor="forms">
                    <p className="form-label">現在の在住国</p>
                  </label>
                  <select id="forms" name="selectForm" onChange={handleChange} onBlur={handleChange}>
                    <option value="1">日本在住</option>
                    <option value="2">メキシコ在住</option>
                    <option value="3">その他</option>
                  </select>
                </Input>
              </form>
              { (sForm.selectForm === "1" && workid !==0) ? (
                <JapaneseForm wid={{workid}}></JapaneseForm>
              ) : (sForm.selectForm === "2" && workid !==0) ? (
                <MexicoForm wid={{workid}}></MexicoForm>
              ) : (
                (sForm.selectForm === "3"&& workid !==0) && <OtherForm wid={{workid}}></OtherForm>
              )}
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}
export default JobForm
